import React, { useRef } from 'react';
import { useIsCompactNavRail } from '../../../components/NavRail/NavRailContextProvider';
import useIsFeatureFlagEnabled from '../../../common/featureFlags/useIsFeatureFlagEnabled';
import { KnownFlags } from '../../../common/featureFlags/KnownFlags';

export const AppSwitcher: React.FC = () => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const sharedComponentRef = useRef<{
    remove: () => void;
  } | null>(null);
  const isCompactNavRail = useIsCompactNavRail();

  React.useEffect(() => {
    if (divRef.current) {
      sharedComponentRef.current = CrispSharedUi.AppSwitcher(divRef.current, {
        include: ['CRA', 'CRS'],
        includeArrow: true,
        placement: 'right-start',
        style: {
          mainButton: {
            height: isCompactNavRail ? 'var(--navrail-compact-width)' : 'var(--navrail-width)',
          },
        },
      });
    }
    return () => {
      sharedComponentRef.current?.remove();
    };
  }, [isCompactNavRail]);

  return <div ref={divRef} />;
};

export const MaybeAppSwitcher: React.FC = () => {
  const appSwitcherIsEnabled = useIsFeatureFlagEnabled(KnownFlags.AppSwitcher);
  return appSwitcherIsEnabled ? <AppSwitcher /> : null;
};
