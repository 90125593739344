import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Maybe } from '@tellurian/ts-utils';
import { ReactComponent as HomeIcon } from '../../../../assets/icons/lettuce/home.svg';
import { ReactComponent as AnalyticsIcon } from '../../../../assets/icons/lettuce/analytics.svg';
import { ReactComponent as ConnectorsIcon } from '../../../../assets/icons/lettuce/connectors.svg';
import { ReactComponent as DataCatalogIcon } from '../../../../assets/icons/lettuce/data-catalog.svg';
import { ReactComponent as AdminIcon } from '../../../../assets/icons/lettuce/admin.svg';
import { ReactComponent as HelpIcon } from '../../../../assets/icons/lettuce/help.svg';
import { ReactComponent as ObservabilityIcon } from '../../../../assets/icons/lettuce/data-pipeline.svg';
import { ReactComponent as LuminateDataPresenceIcon } from '../../../../assets/icons/lettuce/generic-destination.svg';
import { ReactComponent as MdmPlusPlusIcon } from '../../../../assets/icons/lettuce/mdm.svg';
import { ReactComponent as RemaAdmin } from '../../../../assets/icons/lettuce/rema-admin.svg';
import { ReactComponent as ChartIcon } from '../../../../assets/icons/lettuce/chart.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/lettuce/calendar.svg';
import { ExternalLinkTarget } from '../../components/ExternalLink';
import { Feature } from '../../../../utils/features';
import useNavigationProperties from '../../crisp/navigation/useNavigationProperties';
import {
  ExternalLinkMenuListItem,
  MenuListItem,
  MenuListItemWithPopoverSubMenu,
} from '../../components/NavRail/menuItems';
import CurrentUserAvatar from '../../components/Avatar/CurrentUserAvatar';
import { SubMenuType } from '../../components/NavRail/SubMenuPopover';
import { BackdropPredicateContextProvider } from '../../components/NavRail/BackdropPredicateProvider';
import { useParsedQuery } from '../../../../utils/querystringUtils';
import useIsFeatureFlagEnabled from '../../common/featureFlags/useIsFeatureFlagEnabled';
import { KnownFlags } from '../../common/featureFlags/KnownFlags';
import usePersistentState from '../../../../utils/memoryStorage/usePersistentState';
import { MemoryStorageKey } from '../../../../utils/memoryStorage';
import { CustomEventType } from '../lib';
import MaybeCompactNavRail from '../../components/NavRail/MaybeCompactNavRail';
import { RouteId } from '../routing/Routes';
import { path } from '../routing/lib';
import featureTrack from '../../common/tracking/featureTrack';
import UserSettingsMenu, { UserSettingsMenuProps } from './UserSettingsMenu';
import GlobalAdminMenu from './GlobalAdminMenu';
import AnalyticsMenu, { AnalyticsMenuProps } from './AnalyticsMenu';
import LogoLink from './LogoLink';
import { MaybeAppSwitcher } from './AppSwitcher';
import style from './NavRailMenu.module.css';

/**
 * Open a NavRail submenu with the id specified in a persistent state. The submenu will be triggered
 * by a custom event iff the isNavRailReady flag is true.
 * This is useful when one aspect of the application requires a NavRail submenu to be open, but that aspect
 * does not persist across page transitions.
 * @param isNavRailReady set to true when the NavRail has completed loading its constituents. false otherwise.
 */
const useSubmenuToggle = (isNavRailReady = true) => {
  const [navRailSubmenuToToggle, setNavRailSubmenuToToggle] = usePersistentState(
    MemoryStorageKey.NavRailSubmenuIdToggle,
    undefined,
  );

  useEffect(() => {
    if (isNavRailReady && navRailSubmenuToToggle) {
      window.dispatchEvent(
        new CustomEvent(CustomEventType.MenuOpen, { detail: { menuId: navRailSubmenuToToggle } }),
      );
      setNavRailSubmenuToToggle(undefined);
    }
  }, [navRailSubmenuToToggle, setNavRailSubmenuToToggle, isNavRailReady]);
};

const useShowSubmenuWithId = (): Maybe<string> => {
  return (useParsedQuery() as Partial<{ show: string }>).show;
};

export type NavRailMenuProps = {
  accountId?: string;
};

const ExactMatchRouteIds: RouteId[] = ['AccountHome', 'Connectors'];
const RouteIdsWithIframe: RouteId[] = ['BusinessDashboardGroup', 'BiReport'];

const NavRailMenu: React.FC<NavRailMenuProps> = React.memo(function NavRailMenu({ accountId }) {
  const getAccountPath = useMemo(
    () =>
      accountId &&
      ((routeId: RouteId) =>
        path(routeId)({
          accountId,
        })),
    [accountId],
  );

  const {
    location: { pathname },
  } = useHistory();

  const isPathSelected = useMemo(() => {
    const exactMatchPaths: Set<string> = getAccountPath
      ? new Set(ExactMatchRouteIds.map(getAccountPath))
      : new Set();
    return (path: string) => {
      if (getAccountPath && exactMatchPaths.has(path)) {
        return path === pathname;
      }

      return pathname.startsWith(path);
    };
  }, [pathname, getAccountPath]);

  const includeBackdrop = useMemo(() => {
    const shouldInclude = getAccountPath
      ? RouteIdsWithIframe.map(getAccountPath).some(p => pathname.startsWith(p))
      : false;
    return () => shouldInclude;
  }, [pathname, getAccountPath]);

  const getPathProps = useCallback(
    (path: string) => ({ path, isSelected: isPathSelected(path) }),
    [isPathSelected],
  );

  const {
    businessDashboardsEnabled,
    isAccountViewer,
    isAccountAdmin,
    helpUrl,
    isGlobalAdmin,
    loading,
    isRemaAccountAdmin,
    isMdmPlusPlusEnabled,
    isScheduledReportsEnabled,
  } = useNavigationProperties(accountId);
  const isPipelineObservabilityEnabled = useIsFeatureFlagEnabled(KnownFlags.PipelineObservability);
  const isBiReportsEnabled = useIsFeatureFlagEnabled(KnownFlags.PowerBiEmbeddedReports);
  const isLuminateBasicDataPresenceEnabled = useIsFeatureFlagEnabled(
    KnownFlags.LuminateBasicDataPresence,
  );

  const openSubmenuWithId = useShowSubmenuWithId();
  const getMenuItemIdDependentProps = (id: string) => ({
    id,
    showSubMenu: !loading && id === openSubmenuWithId,
  });
  useSubmenuToggle(!loading);

  return (
    <MaybeCompactNavRail alignChildren="space-between" isReady={!loading}>
      <div>
        <LogoLink accountId={accountId} />
        <MaybeAppSwitcher />
      </div>
      <BackdropPredicateContextProvider useIncludeBackdrop={includeBackdrop}>
        <ul id="nav-rail-pages" className={style.menuItems} role="menu">
          {getAccountPath ? (
            <>
              <MenuListItem
                Icon={HomeIcon}
                label="Home"
                onMouseDown={featureTrack(Feature.NavMenuHomepage)}
                {...getPathProps(getAccountPath('AccountHome'))}
              />
              {businessDashboardsEnabled && (
                <MenuListItemWithPopoverSubMenu<AnalyticsMenuProps>
                  accountId={accountId!}
                  RenderContent={AnalyticsMenu}
                  RenderIcon={AnalyticsIcon}
                  label="Analytics"
                  subMenuType={SubMenuType.Expansive}
                  {...getMenuItemIdDependentProps('analyticsMenu')}
                />
              )}
              {isBiReportsEnabled && (
                <MenuListItem
                  Icon={ChartIcon}
                  label="Retail Analytics"
                  {...getPathProps(getAccountPath('BiReport'))}
                />
              )}
              {isScheduledReportsEnabled && (
                <MenuListItem
                  Icon={CalendarIcon}
                  label="Report Schedules"
                  {...getPathProps(getAccountPath('ReportSchedules'))}
                />
              )}
              {isAccountAdmin && (
                <MenuListItem
                  Icon={ConnectorsIcon}
                  label="Connectors"
                  onMouseDown={featureTrack(Feature.NavMenuConnectors)}
                  {...getPathProps(getAccountPath('Connectors'))}
                />
              )}
              {isAccountViewer && (
                <MenuListItem
                  Icon={DataCatalogIcon}
                  label="Data Catalog"
                  onMouseDown={featureTrack(Feature.NavMenuModelDocumentation)}
                  {...getPathProps(getAccountPath('AccountDataCatalog'))}
                />
              )}
              {isAccountAdmin && isPipelineObservabilityEnabled && (
                <MenuListItem
                  Icon={ObservabilityIcon}
                  label="Data Pipeline"
                  {...getPathProps(getAccountPath('ObserveAccountPipeline'))}
                />
              )}
              {isAccountAdmin && isLuminateBasicDataPresenceEnabled && (
                <MenuListItem
                  Icon={LuminateDataPresenceIcon}
                  label="Luminate Data Presence"
                  {...getPathProps(getAccountPath('LuminateBasicDataPresence'))}
                />
              )}
              {isMdmPlusPlusEnabled && (!isAccountViewer || isGlobalAdmin) && (
                <MenuListItem
                  Icon={MdmPlusPlusIcon}
                  label="MDM"
                  onMouseDown={featureTrack(Feature.MdmPlusPlus)}
                  {...getPathProps(getAccountPath('Mdm'))}
                />
              )}
            </>
          ) : (
            <>
              {isGlobalAdmin && isPipelineObservabilityEnabled && (
                <MenuListItem
                  Icon={ObservabilityIcon}
                  label="Data Pipeline"
                  {...getPathProps(path('ObservePipeline')())}
                />
              )}
            </>
          )}
          {isRemaAccountAdmin && (
            <MenuListItem
              Icon={RemaAdmin}
              label="REMA Admin"
              onMouseDown={featureTrack(Feature.NavMenuRemaConnect)}
              {...getPathProps(path('RemaConnectAdminDiagnostics')())}
            />
          )}
          {helpUrl && (
            <ExternalLinkMenuListItem
              Icon={HelpIcon}
              label="Help"
              href={helpUrl}
              onClick={featureTrack(Feature.NavMenuKnowledgeBase)}
              target={ExternalLinkTarget.knowledgeBase}
            />
          )}
        </ul>
        <ul id="nav-rail-settings">
          {isGlobalAdmin && (
            <MenuListItemWithPopoverSubMenu<UserSettingsMenuProps>
              {...getMenuItemIdDependentProps('adminMenu')}
              accountId={accountId}
              isAccountAdmin={isAccountAdmin}
              RenderContent={GlobalAdminMenu}
              RenderIcon={AdminIcon}
              label="Admin"
              placement="end"
            />
          )}
          <MenuListItemWithPopoverSubMenu<UserSettingsMenuProps>
            {...getMenuItemIdDependentProps('settingsMenu')}
            accountId={accountId}
            isAccountAdmin={isAccountAdmin}
            RenderContent={UserSettingsMenu}
            RenderIcon={CurrentUserAvatar}
            onClick={featureTrack(Feature.NavMenuSettings)}
            label="Settings"
            placement="end"
          />
        </ul>
      </BackdropPredicateContextProvider>
    </MaybeCompactNavRail>
  );
});

export default NavRailMenu;
